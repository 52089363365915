import * as React from 'react'

const HeartIcon = (props) =>
  <svg width={props.width || "20px"}
       height={props.height || "20px"}
       version="1.0" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 342.000000 315.000000"
       preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,315.000000) scale(0.100000,-0.100000)" fill={props.fill} stroke="none">
          <path d="M827 3130 c-128 -23 -286 -88 -394 -161 -69 -47 -198 -174 -249 -245
            -60 -84 -127 -230 -155 -339 -33 -125 -33 -361 -1 -477 28 -102 60 -180 113
            -275 39 -71 110 -145 804 -841 l760 -762 760 762 c694 696 765 770 804 841 53
            94 85 173 113 275 31 111 31 363 0 474 -51 185 -123 316 -246 445 -137 144
            -278 229 -472 285 -68 20 -103 23 -249 23 -147 0 -181 -3 -255 -23 -148 -41
            -300 -122 -410 -219 l-45 -40 -55 47 c-78 67 -140 106 -233 150 -135 63 -220
            82 -392 86 -82 1 -172 -1 -198 -6z"/>
        </g>
  </svg>

export default HeartIcon
