import http from './http'
import config from '../services/config'
import { convertObjectToQueryString } from '../utils/utils'

export const getTopCategories = async (pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories${params.length ? `?${params}` : ''}`)
}

export const getSubCategories = async (categoryID, pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/${categoryID}/categories${params.length ? `?${params}` : ''}`)
}

export const getCategoryIDByFriendlyID = async (categoryFriendlyID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/id?friendlyID=${categoryFriendlyID}`)
}

export const getCategory = async (categoryID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/${categoryID}`)
}

export const getCategoryTree = async (depth) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/tree?depth=${depth}`)
}
