import http from './http'
import config from '../services/config'
import { convertObjectToQueryString } from '../utils/utils'

export const getProductIDByFriendlyID = async (friendlyID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/id?friendlyID=${friendlyID}`)
}

export const getProducts = async (categoryID, pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/categories/${categoryID}/products${params.length ? `?${params}` : ''}`)
}

export const getProductsByIDs = async (IDs) => {
  const sIDs = IDs.map((val, ind) => `productIDs[${ind}]=${val}`).join('&')
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products?${sIDs}`)
}

export const getProductByID = async (productID, retrieveTypeSpecificData) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/${productID}?retrieveTypeSpecificData=${retrieveTypeSpecificData}`)
}

export const getProductThumbnails = async (productID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/${productID}/thumbnails`)
}

export const createProofPreview = async (orderItemID, properties) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/preview`, {properties})
}

export const getProofPreview = async (orderItemID, previewID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/preview/${previewID}`)
}

export const downloadProofPreview = async (orderItemID, previewID, fileName) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/preview/${previewID}/download?fileName=${fileName}`, undefined, { download: true })
}

export const createProof = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/proof`, {})
}

export const downloadProof = async (orderItemID, fileName) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/proof/download?fileName=${fileName}`, undefined, { download: true })
}

export const search = async (search, pageNumber, pageSize) => {
  const params = convertObjectToQueryString({ search, pageNumber, pageSize })
  return await http.get(`${config.getValue('apiUrl')}/v1/store/products/search${params.length ? `?${params}` : ''}`)
}

export const checkProofStatus = async (orderItemIds) => {
  const params = orderItemIds.map(id => `orderItemIds=${id}`).join('&')
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/proof${params.length ? `?${params}` : ''}`)
}

export const productType = {
  Undefined: -1,
  Dynamic: 1,
  Static: 2,
  Email: 3,
  Uploaded: 4,
  Composite: 5,
  DynamicWithPurl: 6,
  EmailWithPurl: 7,
  CircleProject: 8,
  PrintTouchPoint: 9,
  EmailTouchPoint: 10,
  WebTouchPoint: 11,
  PdfOnDemandTouchPoint: 12,
  TriggeredEmailTouchPoint: 13,
  Kit: 14
}
