import React, { useEffect, useState } from 'react'
import { ReactComponent as LeftArrow } from '$assets/icons/left_arrow.svg'
import { ReactComponent as RightArrow } from '$assets/icons/right_arrow.svg'
import { Carousel, CarouselItem } from 'reactstrap'
import { ImageZoom } from '$core-components'
import DotCarousel from '../DotCarousel'
import { useSwipeable } from 'react-swipeable'
import './DynamicImageViewer.scss'

const DynamicImageViewer = ({thumbnails, onChange, disabled, isStretched = true}) => {
  const [images, setImages] = useState([])
  const [activeImage, setActiveImage] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [zoomed, setZoomed] = useState(false)
  useEffect(() => {
    setImages(thumbnails)
    setActiveImage(0)
  },[thumbnails])

  useEffect(() => {
    if (onChange) {
      onChange(activeImage)
    }
  }, [activeImage, onChange])

  const next = () => {
    if (animating) return
    const nextIndex = activeImage === images.length - 1 ? 0 : activeImage + 1
    setActiveImage(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeImage === 0 ? images.length - 1 : activeImage - 1
    setActiveImage(nextIndex)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => images.length > 1 && next(),
    onSwipedRight: () => images.length > 1 && previous(),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  })

  const handleImageZoom = (zoom) => {
    setZoomed(zoom)
  }
  return <>
    {!zoomed && images?.length > 1 &&
      <span className="preview-arrows-left">
            <LeftArrow width="40px" height="40px" onClick={previous} />
          </span>
    }
    {images?.length > 0 &&
      <div className="preview-image dynamic-product-approval" {...handlers}>
        <Carousel
          interval={false}
          previous={previous}
          next={next}
          activeIndex={activeImage}>
          {images.map(image => (<CarouselItem
            key={image.Url}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
          >
            <ImageZoom src={images[activeImage].Url} onImageZoomed={(zoom) => handleImageZoom(zoom)} isStretched={isStretched}/>
          </CarouselItem>))}
        </Carousel>
      </div>}
    {!zoomed &&
        <>
          {images?.length > 1 &&
              <span className="preview-arrows-right">
              <RightArrow width="40px" height="40px" onClick={next} />
            </span>
          }
          <div className="preview-page-title">
            {images?.length > 1  && <span>{images[activeImage].DisplayName}</span>}
          </div>
          {images?.length > 1 &&
          <DotCarousel images={images} active={activeImage} disabled={animating || disabled} onDotClick={(id) => setActiveImage(id)} />
          }
        </>

    }


  </>
}

export default DynamicImageViewer
