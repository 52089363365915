import React, { Component } from 'react'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { getIsNGProduct } from '$themeservices/utils'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import themeContext from '$ustoreinternal/services/themeContext'
import LoadingDots from '$core-components/LoadingDots'
import { UStoreProvider } from '@ustore/core'
import Layout from '../components/layout/index'
import ProductItem from '../components/products/ProductItem'
import './FavoriteProducts.scss'

class FavouriteProducts extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isFavourite: false,
      favoriteProducts: [],
      isLoadedStatus: false,
    }
  }

  getData = async () => {
    const { storeFriendlyID } = themeContext.get()
    const currentUserId = await UStoreProvider.state.get().currentUser.FriendlyID
    this.favoriteProductsApi('GetFavoritesByUser', {
      'UserId': currentUserId,
      'StoreId': storeFriendlyID,
    }, this.getFavoritesByUserCallback)
  }

  getFavoritesByUserCallback = async (data) => {
    const mainData = data.GetFavoritesByUserResult && JSON.parse(data.GetFavoritesByUserResult.Data)
    data.GetFavoritesByUserResult && mainData.map(async (item, counter) => {
      const productID = await UStoreProvider.api.products.getProductIDByFriendlyID(item.ProductId)
      const model = await UStoreProvider.api.products.getProductByID(productID)
      const thumbnails = await UStoreProvider.api.products.getProductThumbnails(productID)
      const currentThumbnail = thumbnails.Thumbnails[0].Url
      Object.assign(model, {
        ImageUrl: currentThumbnail
      })

      this.setState(prevState => ({
        favoriteProducts: [...prevState.favoriteProducts, model]
      }))

      if (counter === mainData.length - 1) {
        this.setState({ isLoadedStatus: true })
      }
    })

    if (mainData.length === 0) {
      this.setState({ isLoadedStatus: true })
    }
  }

  favoriteProductsApi = async (method, body, callback) => {
    await fetch(UStoreProvider.contextService.context['apiUrl'].replace('/uStoreRestAPI', '') + '/uStore/FavoriteProducts/FPService.svc/' + method, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        response.json().then((data) => callback(data))
      })
  }

  byField = (field) => {
    return (a, b) => a[field] > b[field] ? 1 : -1
  }

  componentDidMount () {
    setTimeout(() => {
      this.getData()
    }, 1000)
  }

  render () {
    const { favoriteProducts } = this.state
    const filteredProducts = favoriteProducts.sort(this.byField('FriendlyID'));
    return (
      <Layout {...this.props}>
        {this.state.isLoadedStatus ?
          (filteredProducts.length > 0 ?
            <>
            <div className="title-wrapper">
              <div className="title">
                Favorite Products
              </div>              
            </div>
            <div className="favorite-products-wrap">
              {
                filteredProducts.map((model) => {
                  return <ProductItem
                    key={model.ID}
                    model={model}
                    productNameLines="2"
                    descriptionLines="4"
                    url={getIsNGProduct(model) ?
                      urlGenerator.get({ page: 'products', id: model.FriendlyID, name: decodeStringForURL(model.Name) })
                      :
                      urlGenerator.get({ page: 'product', id: model.FriendlyID, name: decodeStringForURL(model.Name) })
                    }
                  />
                })
              }
            </div>
            </>
            :
            <div className={'no-products-father-wrap'}>No favorite products</div>)
          :
          <div className={'loading-dots-father-wrap'}>
            <LoadingDots className={'header-loading-dots'}/>
          </div>}
      </Layout>
    )
  }
}

export default FavouriteProducts
