import http from './http'
import httpUpload from './httpUpload'
import httpDeleteFiles from './httpDeleteFiles'
import config from '../services/config'

export const getUserOrdersSummary = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/summary`)
}

export const addOrderItem = async (productID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/draft/items?productID=${productID}`)
}

export const getOrderItem = async (orderItemID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}`)
}

export const updateOrderItem = async (orderItemID, orderItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}`, orderItem)
}

export const getPriceOrderItem = async (orderItemID, orderItem) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/price/calculate`, orderItem)
}

export const reorder = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/submitted/items/${orderItemID}/reorder`)
}

export const getLastOrder = async (productID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/submitted/items/last/status?productID=${productID}`)
}

export const addToCart = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/addToCart`)
}

export const saveForLater = async (orderItemID) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/saveForLater`)
}

export const getDeliveryServices = async (orderItemID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/deliveryServices`)
}

export const getProperties = async (orderItemID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/form`)
}

export const updateProperties = async (orderItemID, data) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/form`, data)
}

export const updatePropertiesState = async (orderItemID, data) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/form/state`, data)
}

export const uploadFile = async (orderItemID, propertyID, data) => {
  return await httpUpload(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/${propertyID}/files`, data)
}

export const deleteFiles = async (orderItemID, propertyID, names) => {
  return await httpDeleteFiles(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}/properties/${propertyID}/files?${names}`)
}

export const getRepositoryDetails = async (guid) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/system/fileRepositories/${guid}`)
}

export const getCartInfo = async (listId = null) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart?ListID=${listId}`)
}

export const getWishListInfo = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList`)
}

export const deleteCartItem = async (orderItemID) => {
  return await http.delete(`${config.getValue('apiUrl')}/v1/store/login/user/orders/unsubmitted/items/${orderItemID}`)
}

export const clearCart = async (orderItemIds = null, listId = null) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/clear`, {
    OrderItemIDs: orderItemIds,
    ListID: listId,
  })
}

export const clearWishlist = async (orderItemIds = null) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/clear`, {
    OrderItemIDs: orderItemIds
  })
}

export const validateCart = async (isChecked = null, listId = null) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/validations?isChecked=${isChecked}&ListID=${listId}`)
}

export const validateWishList = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/validations`)
}

export const getCartPricingInfo = async (isChecked = null, listId = null) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/price?isChecked=${isChecked}&ListID=${listId}`)
}

export const getWishListPricingInfo = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/price`)
}

export const updateCartItem = async (orderItemID, orderItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/${orderItemID}`, orderItem)
}

export const updateWishListItem = async (orderItemID, orderItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/wishList/${orderItemID}`, orderItem)
}

export const updateCart = async (cartItem) => {
  return await http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart`, cartItem)
}

export const createCartList = async (name, externalId = null, externalUrl = null) => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists`, {
    Name: name,
    ExternalID: externalId,
    ExternalUrl: externalUrl
  })
}

export const getListId = async (externalId) => {
  return http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/id?externalID=${externalId}`)
}

export const getListInfo = async (listId) => {
  return http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}/details`)
}

export const getLists = async () => {
  return http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists`)
}

export const deleteList = async (listId) => {
  return http.delete(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}`)
}

export const exportList = async (listId) => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}/export`)
}

export const renameList = async (listId, title) => {
  return http.put(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}`, { Name: title })
}

export const revertModifications = async () => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/approval/revert`, {})
}

export const duplicateItem = async (orderItemID) => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/${orderItemID}/duplicate`, {})
}

export const duplicateList = async (listId) => {
  return http.post(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/lists/${listId}/duplicate`, {})
}
