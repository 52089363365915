import React from 'react'

import { t } from '$themelocalization'
import { CrossCloseButton } from '$core-components'
import './CookiesConsent.scss'

const CookiesConsent = ({ onCookiesPolicyClicked, onCloseCookiesConsentClicked }) => {
  const localizeCookiesConsent = () => {
    const localizationString = t('GdprRibbon.Cookies_consent')
    const stringArray = localizationString.split('%{0}')
    stringArray.splice(1, 0, <span onClick={onCookiesPolicyClicked} key="content-policy">{t('GdprRibbon.Cookie_policy')}</span>)
    return stringArray
  }

  return (
    <div className="cookies-consent-container">
      <CrossCloseButton className="consent"
        onClick={onCloseCookiesConsentClicked}/>
      <div className="cookies-consent-title">{t('GdprRibbon.This_website_uses_cookies')}</div>
      <div className="cookies-consent-description">
        {localizeCookiesConsent()}
      </div>
    </div>
  )
}

export default CookiesConsent
