import React, { Component } from 'react'
import './ImageLoader.scss'
import { debounce } from 'throttle-debounce'

class ImageLoader extends Component {

  constructor (props) {
    super(props)
    this.imageOnLoad = this.imageOnLoad.bind(this)
    this.imageOnError = this.imageOnError.bind(this)
    this.image = React.createRef()
  }

  componentDidMount () {
    const img = this.image.current
    if (img && img.complete) {
      this.imageOnLoad()
    }
    if (this.props.isStretched) {
      window.addEventListener('resize', this.imageOnLoad)
    }
  }

  componentWillUnmount () {
    if (this.props.isStretched) {
      window.removeEventListener('resize', this.imageOnLoad)
    }
  }

  imageOnLoad = debounce(100, () => {
    const img = this.image.current
    if (img) {
      img.classList.remove('hide')
      img.classList.add('show')
      img.previousSibling.classList.remove('show')
      img.previousSibling.classList.add('hide')
      if (this.props.isStretched) {
        let container = img.parentElement
        const borderWidth = parseInt(getComputedStyle(img).borderWidth, 10) * 2;
        let adjustedContainerWidth = container.offsetWidth - borderWidth;
        let adjustedContainerHeight = container.offsetHeight - borderWidth;

        while (adjustedContainerWidth <= 0 || adjustedContainerHeight <= 0) {
          container = container.parentElement
          adjustedContainerWidth = container.offsetWidth - borderWidth;
          adjustedContainerHeight = container.offsetHeight - borderWidth;
        }

        if (img.naturalWidth / img.naturalHeight > adjustedContainerWidth / adjustedContainerHeight) {
          img.style.width = `${adjustedContainerWidth}px`
          img.style.height = 'auto'
        } else {
          img.style.width = 'auto'
          img.style.height = `${adjustedContainerHeight}px`
        }
      }
    }
  })

  imageOnError = () => {
    const imageElem = this.image.current
    if (imageElem) {
      imageElem.src = require(`$assets/images/default.png`)
      imageElem.classList.remove('hide')
      imageElem.classList.add('show')
      imageElem.previousSibling.classList.remove('show')
      imageElem.previousSibling.classList.add('hide')
    }
  }

  render () {
    return (
      <div className={`image-loader ${this.props.className ? this.props.className : ''}`}>
        <div className="animated loading"/>
        <img
          alt=''
          ref={this.image}
          src={this.props.src}
          onError={this.imageOnError}
          onLoad={this.imageOnLoad}
          className="hide"
        />
      </div>
    )
  }
}

export default ImageLoader
