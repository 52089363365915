import http from './http'
import config from '../services/config'

const duplicateItemMocks = [
  {Name: 'Test',
    Output: null,
    Progress: null,
    Status: 1,
    Total: 1,
    Type: 1},
  {Name: 'Test',
    Output: null,
    Progress: null,
    Status: 2,
    Total: 1,
    Type: 1}
]

const duplicateKitItemMocks = [
  {Name: 'Kit item',
    Output: null,
    Progress: 1,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'Kit item',
    Output: null,
    Progress: 2,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'Kit item',
    Output: null,
    Progress: 3,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'Kit item',
    Output: null,
    Progress: 3,
    Status: 2,
    Total: 3,
    Type: 2}
]



const duplicateListMocks = [
  {Name: 'My list to check length',
    Output: null,
    Progress: 1,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'My list to check length',
    Output: null,
    Progress: 2,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'My list to check length',
    Output: null,
    Progress: 3,
    Status: 1,
    Total: 3,
    Type: 2},
  {Name: 'My list to check length',
    Output: {
      CompletedCount: 2,
    },
    Progress: 3,
    Status: 4,
    Total: 3,
    Type: 3}
]


let mockIndex = 0


export const getActivities = async (activitiesIDs) => {
  // const mock = duplicateListMocks[mockIndex++]
  // mock.ActivityID = activitiesIDs[0]
  // return Promise.resolve(mock)
  const params = activitiesIDs.map(id => `activityIDs=${id}`).join('&')
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/activities?${params}`)
}
