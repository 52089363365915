import providerState from './providerState'
import { GET_ASSETS, GET_ASSET, DELETE_ASSET, UPLOAD_ASSET } from '../redux/actionsTypes'
import { getAssets, getAsset, deleteAsset, uploadAsset } from '../api/assets'


class Assets {
  async getAssets (propertyID) {
    const assets = await getAssets(propertyID)
    providerState.dispatch({ type: GET_ASSETS, data: assets })
    return assets
  }

  async getAsset (assetID) {
    const asset = await getAsset(assetID)
    providerState.dispatch({ type: GET_ASSET, data: asset })
    return asset
  }

  async deleteAsset (assetID) {
    await deleteAsset(assetID)
    providerState.dispatch({ type: DELETE_ASSET, data: assetID })
    return assetID
  }

  async uploadAsset (propertyID, data, replaceIfExists) {
    const asset = await uploadAsset(propertyID, data, replaceIfExists)
    providerState.dispatch({ type: UPLOAD_ASSET, data: asset })
    return asset
  }
}

export default new Assets()
