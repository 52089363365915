/**
 * A component to display inventory information of a product
 *
 * @param {object} model - ProductInventoryModel containing data regarding inventory of the product
 * @param {number} minQuantity - the minimum quantity of units that can be ordered from the product
 * @param {boolean} hideInStock (false) - Hide the 'In Stock #####' label if is in stock.
 * @param {string} pluralName - the plural name of the item type.
 */
import React from 'react'
import { t } from '$themelocalization'
import './Inventory.scss'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'

export const isOutOfStock = (quantity, minQuantity, AllowOutOfStockPurchase) => {
  return (quantity < minQuantity && !AllowOutOfStockPurchase)
}

const Inventory = (props) => {
  const { model, minQuantity, hideInStock = false, pluralName, singleName, pn = 'none' } = props;
  const backorderMessage = getVariableValue('--product-setup-backorder-message', "Out of stock. Delivery may be delayed.")
  const outOfStockMessage = getVariableValue('--product-setup-out-of-stock-message', "Out of stock.")


  return (
    <span className="inventory">
      {
        (model && model.Quantity < minQuantity)
          ? (
            model.AllowOutOfStockPurchase
              //? <div className='warning'>{t('Inventory.Out_of_stock_Delivery_delayed')}</div>
              ? <div className={`warning backorder-message backorder-message-${pn}`}>
                  {backorderMessage}
                </div>
              //: <div className='error'>{t('Inventory.Out_of_stock')}</div>
              : <div className={`error out-of-stock-message out-of-stock-message-${pn}`}>
                  {outOfStockMessage}
                </div>
          ) : null
      }
      
      {!hideInStock && model && model.Quantity >= minQuantity
        ? <div className='inStock'>
          {`${t('Inventory.In_stock')}`}
          <span className='inventory-qty'>
            {`${model.Quantity} ${model.Quantity === 1 ? singleName || 'Item' : pluralName || 'Items'}`}
          </span>
        </div>
        : null
      }


    </span>
  )
}

export default Inventory
