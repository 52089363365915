import providerState from './providerState'
import { SET_CURRENT_CURRENCY, SET_CURRENT_CULTURE_BY_LANGUAGE } from '../redux/actionsTypes'

/**
 * Represents the culture logic in the system
 */
class Culture {
  /**
   * Set **state.currentCurrency** with the requested currency model
   * @param {CurrencyModel} currentCurrency - the requested currency to set as the current currency
   */
  setCurrentCurrency (currentCurrency) {
    providerState.dispatch({ type: SET_CURRENT_CURRENCY, data: currentCurrency })
  }

  /**
   * Set **state.currentCurrency** according the the language code requested
   * @param {string} languageCode - the requested language code
   */
  setCurrentCultureByLanguage (languageCode) {
    providerState.dispatch({ type: SET_CURRENT_CULTURE_BY_LANGUAGE, data: languageCode })
  }

  /**
   * Get the converted price according to the current currency in the state
   * @param {ProductPriceModel} priceModel - the price model to be converted
   * @returns {json} - price, tax and priceIncludingTax that are converted by the current currency
   */
  getConvertedPrices (priceModel) {
    const { currentCurrency } = providerState.get()
    return {
      price: priceModel.Price * currentCurrency.ConversionRate,
      tax: priceModel.Tax * currentCurrency.ConversionRate,
      priceIncludingTax: (priceModel.Price + priceModel.Tax) * currentCurrency.ConversionRate
    }
  }
}

export default new Culture()
