import http from './http'
import config from '../services/config'
import httpUpload from './httpUpload'

export const getAssets = async (propertyID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/assets/?propertyID=${propertyID}`)
}

export const getAsset = async (assetID) => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/assets/${assetID}`)
}

export const deleteAsset = async (assetID) => {
  await http.delete(`${config.getValue('apiUrl')}/v1/store/login/user/assets/${assetID}`)
  return assetID
}

export const uploadAsset = async (propertyID, data, replaceIfExists = false) => {
  const r = await httpUpload(`${config.getValue('apiUrl')}/v1/store/login/user/assets/?propertyID=${propertyID}${replaceIfExists ? '&replaceIfExists=true' : ''}`, [data])
  return r[0]
}
